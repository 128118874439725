import { render, staticRenderFns } from "./ForgotModalEN.vue?vue&type=template&id=7bfa489f&scoped=true"
import script from "./ForgotModalEN.vue?vue&type=script&lang=js"
export * from "./ForgotModalEN.vue?vue&type=script&lang=js"
import style0 from "./ForgotModalEN.vue?vue&type=style&index=0&id=7bfa489f&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bfa489f",
  null
  
)

export default component.exports